import styled from "styled-components";
import { lightColor2, secondaryDark3, whiteColor } from "../GlobalStyle";

export const NavbarStyle = styled.div`
  background: ${whiteColor};
  padding: 6px;
  border-radius: 10px;
  p {
    margin-bottom: 0px;
  }
  img {
    cursor: pointer;
  }
  .profile-img {
    height: 38px;
  }
  .search-input {
    outline: none;
    &:focus {
      outline: none;
    }
  }
  .notifications {
    border-radius: 10px;
    border: 1px solid ${lightColor2};
    padding: 8px;
    background-color: ${whiteColor};
  }
  .filter-area {
    border-radius: 10px;
    border: 1px solid ${lightColor2};
    background-color: ${whiteColor};
    &-category {
      border-right: 1px solid ${lightColor2};
    }
  }
  .date-area {
    border-radius: 10px;
    border: 1px solid ${lightColor2};
    background-color: ${whiteColor};
    padding: 8px;
  }
  .right-side {
    .name {
      margin-bottom: 0;
      color: ${secondaryDark3};
      font-size: 14px;
    }
    img {
      margin-left: 8px;
    }
  }
`;
export default NavbarStyle;
