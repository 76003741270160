import { useState } from "react";
import SidebarStyle from "./style";
import { useNavigate, useSearchParams } from "react-router-dom";
import ic_dashboard from "../../assets/icons/ic_dashboard.svg";
import ic_booking from "../../assets/icons/ic_booking.svg";
import ic_logo from "../../assets/icons/logo.svg";
import CustomButton from "../CustomButton/CustomButton";
import { lightDark2, lightGrey12 } from "../GlobalStyle";

const Sidebar = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [sidebarData, setSidebarData] = useState([
    {
      items: [
        { portal: "USER", title: "User" },
        { portal: "ADMIN", title: "Admin" },
      ],
      icon: ic_booking,
      list_link: "users-list",
      name: "Translation",
      expand: false,
    },
  ]);

  const expandMenu = (index: number): void => {
    sidebarData[index].expand = !sidebarData[index].expand;
    setSidebarData([...sidebarData]);
  };

  const naivgateDashboard = (): void => {
    navigate("/dashboard");
  };

  return (
    <SidebarStyle
      className={`${true ? "d-flex flex-column align-items-center" : "d-none"}`}
    >
      <img
        className="logo cursor-pointer"
        onClick={() => navigate("/")}
        src={ic_logo}
        alt="logo"
      />
      <div className="d-flex gap-2 menu-items">
        <img src={ic_dashboard} alt="dashboard" />
        <p onClick={naivgateDashboard}>Dashboard</p>
      </div>
      {sidebarData.map(({ name, items, icon, expand }, index) => (
        <div key={index} className="inner-container gap-2">
          <div className="d-flex gap-2">
            <img src={icon} alt="dashboard" />
            <p
              onClick={() => {
                expandMenu(index);
                navigate("translation-list?portal=USER&page=1");
              }}
            >
              {name}
            </p>
          </div>
          <div className={`expand-menu ms-3 ${true ? "d-block" : "d-none"}`}>
            {items.map(({ title, portal }, index) => (
              <div
                key={index}
                className={`${(searchParams.get("portal") === portal) ? "selected" : ""
                  } w-100 mt-20`}
              >
                <p
                  onClick={() => {
                    // setPortalType(portal);
                    navigate(`/translation-list?portal=${portal}`);
                  }}
                >
                  {title}
                </p>
              </div>
            ))}
          </div>
        </div>
      ))}
      <div className="login-btn">
        <CustomButton
          bgcolor={lightGrey12}
          color={lightDark2}
          padding="6px"
          width="80%"
          textTransform="Captilize"
          type="button"
          title="Logout"
          margin="auto"
          fontSize="16px"
          clicked={() => navigate("/login")}
        />
      </div>
    </SidebarStyle>
  );
};

export default Sidebar;
