
const Dashboard = () => {
  return (
    <>
      <h2 className="text-center my-3">Dashboard</h2>
    </>
  );
};

export default Dashboard;
