import React, { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { fontFamilyMedium } from "../../components/GlobalStyle";
import { DataType } from "./TranslationList";

type translationOptionProps = {
  setShowTranslationOptions: Dispatch<SetStateAction<boolean>>;
  translationData: DataType;
  page: string|null;
  screen:string| null;
  portal:string|null
};
const TranslatioOptions: React.FC<translationOptionProps> = ({
  setShowTranslationOptions,
  translationData,
  page,
  screen,
  portal
}) => {
  const navigate = useNavigate();

  const createOptions = [
    {
      title: "Edit",
      doTask: () => {
        navigate("/update-translation", {
          state: {
            ...translationData,
            page,
            screen,portal
          },
        });
        setShowTranslationOptions(false);
      },
    },
    {
      title: "Delete",
      doTask: () => {
        setShowTranslationOptions(false);
      },
    },
  ];
  return (
    <Wrapper className="mt-4">
      <ul>
        {createOptions.map(({ title, doTask }, index) => {
          return (
            <div key={index}>
              <li onClick={doTask}>{title}</li>
            </div>
          );
        })}
      </ul>
    </Wrapper>
  );
};

export default TranslatioOptions;

const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 2px 5px 4px rgba(0, 0, 0, 0.3);
  font-weight: 500;
  transition: all 0.4s;
  width: 200px;
  &:focus {
    width: 100%;
  }
  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    list-style: none;
    text-decoration: none;
    padding-left: 4px;
    margin-bottom: 0;

    li {
      padding: 7px 7px;
      color: rgba(0, 0, 0, 0.7);
      box-shadow: 0px 0.2px 0.2px rgba(0, 0, 0, 0.3);
      font-weight: 500;
      cursor: pointer;
      transition: all 0.1s;
      font-family: ${fontFamilyMedium};
      &:focus {
        filter: brightness(50%);
        background-color: rgba(0, 0, 0, 0.9);
        color: rgba(255, 255, 255, 0.3);
        transform: scale(1);
      }
    }
  }
`;
