import { Dropdown, Table } from "antd";
import TransactionListStyle, { ScreenItemStyle } from "./style";
import MobileTable from "./MobileTable";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import view_more from "../../assets/icons/view_more.svg";
import { transactions_list_url } from "../../utils/api_urls";
import { TransactionDetailTypes } from "./type";
import CustomModal from "../../components/Modal/CustomModal";
import TransactionDetails from "./TranslationDetails";
import { useEffect, useState } from "react";
import TranslatioOptions from "./TranslationOptions";
import plus_icon from "../../assets/icons/plus_icon.svg";
import { TertiaryHeading } from "../../components/GlobalStyle";
import { usePostEventTrue } from "../../hooks/usePostEvent";
import type { ColumnsType } from 'antd/es/table';
import Loader from "../../components/Loader/Loader";

export interface DataType {
  id: number;
  screenName: string;
  labelKey: string;
  English: string;
  Portuguese: string;
  Urdu: string;
  Arabic: string;
  Spanish: string;
  Chinese: string;
  Hindi: string;
  Bengali: string;
  Russian: string;
  French: string;
  German: string;
  Japanese: string;
  Punjabi: string;
  Swahili: string;
  Telugu: string;
  Marathi: string;
  Indonesian: string;
}
const TransactionList = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const { state } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams("");
  const { loading, apiDataPromise, data, error } = usePostEventTrue();
  const [showModal, setShowModal] = useState(false);
  const [transationData, setTransationData] = useState(
    {} as DataType
  );
  const [showOptions, setShowOptions] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record: DataType) =>
    record.id === +editingKey;
  const [selectedScreen, setSelectedScreen] = useState("");
  const [translationsData, setTranslationsData] = useState([] as TransactionDetailTypes[]);

  // fetch portal data
  useEffect(() => {
    const portal = searchParams.get('portal');
    if (portal) {
      (async () => {
        await apiDataPromise(transactions_list_url, {
          roleType: portal,
        });
        if (error) {
          setTranslationsData([] as TransactionDetailTypes[]);
        }

      })()
    }
  }, [searchParams]);

  const defaultPageDataHandler = () => {
    setPage(1);
  }

  useEffect(() => {
    let oldTranslationsData = [] as TransactionDetailTypes[];
    const screenNameQuery = searchParams.get("screenName");
    if (screenNameQuery) {
      oldTranslationsData = Object.entries(data as TransactionDetailTypes[])
        .flatMap(([_subject, translations]) => translations).filter(({ screenName }) => screenName === screenNameQuery);
      if (oldTranslationsData.length > 0) {
        searchParams.set("screenName", oldTranslationsData[0].screenName);
        setSearchParams(searchParams);
        setTranslationsData(oldTranslationsData);
      }
    }
    if (selectedScreen) {
      defaultPageDataHandler();
      const oldTranslationsData = Object.entries(data as TransactionDetailTypes[])
        .flatMap(([_subject, translations]) => translations).filter(({ screenName }) => screenName === selectedScreen);
      if (oldTranslationsData.length > 0) {
        searchParams.set("screenName", oldTranslationsData[0].screenName);
        setSearchParams(searchParams);
        setTranslationsData(oldTranslationsData);
      }
    }

  }, [selectedScreen, searchParams]);

  useEffect(() => {
    if (selectedScreen) {
      setPage(1);
      searchParams.set("page", "1");
      searchParams.set("screenName", selectedScreen);
      setSearchParams(searchParams);
    }
  }, [selectedScreen])


  useEffect(() => {
    let oldTranslationsData = [] as TransactionDetailTypes[];
    if (data) {
      if (state) {
        searchParams.set("portal", state.portal);
        searchParams.set("screenName", state.screenName);
        setPage(+state.page)
        setSearchParams(searchParams);
      } else {
        setPage(1);
        if (searchParams.get("screenName")) {
          oldTranslationsData = Object.entries(data as TransactionDetailTypes[])
            .flatMap(([_subject, translations]) => translations).filter(({ screenName }) => screenName === searchParams.get("screenName"));
        } else {
          oldTranslationsData = Object.entries(data as TransactionDetailTypes[])
            .flatMap(([_subject, translations]) => translations);
        }
        if (searchParams.get("page")) {
          setPage(+searchParams.get("page")!);
        } else {
          searchParams.set("page", "1");
        }

        if (oldTranslationsData.length > 0) {
          setSearchParams(searchParams);
          setTranslationsData(
            oldTranslationsData
          );
        }
      }

    }

  }, [data]);


  useEffect(() => {
    const portal = searchParams.get('portal');
    if (portal) {
      searchParams.set('portal', portal);
      setSearchParams(searchParams)
    }
  }, [searchParams])

  // table configuration
  const columns: ColumnsType<DataType> = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: "70px",
      render: (text: any, record: any) => (
        <p
          className="table-link mb-0 cursor-pointer"
          onClick={() => {
            setTransationData(record);
            setShowModal(true);
          }}
        >
          {text}
        </p>
      ),
    },
    {
      title: "English",
      dataIndex: "English",
      key: "en",
      render: (text: any, record: DataType) => {
        return (
          <p
            className="mb-0 cursor-pointer"
            onClick={() => {
              setTransationData(record);
              setShowModal(true);
            }}
          >
            {text}
          </p>
        )
      },
    },
    {
      title: "Spanish",
      dataIndex: "Spanish",
      key: "spanish",
      render: (text: any, record: DataType) => {
        return (
          <p
            onClick={() => {
              setTransationData(record);
              setShowModal(true);
            }}
            className="mb-0 cursor-pointer"
          >
            {text}
          </p>
        )
      },
    },
    {
      title: "Portuguese",
      dataIndex: "Portuguese",
      key: "portuguese",
      render: (text: any, record: DataType) => (
        <p
          className="mb-0 cursor-pointer"
          onClick={() => {
            setTransationData(record);
            setShowModal(true);
          }}
        >
          {text}
        </p>
      ),
    },
    {
      title: "Arabic",
      dataIndex: "Arabic",
      key: "arabic",
      render: (text: any, record: DataType) => (
        <p
          onClick={() => {
            setTransationData(record);
            setShowModal(true);
          }}
          className="mb-0 cursor-pointer"
        >
          {text.length > 50 ? text.slice(0, 50) + "..." : text}
        </p>
      ),
    },
    {
      title: "Urdu",
      dataIndex: "Urdu",
      key: "urdu",
      render: (text: any, record: DataType) => (
        <p
          onClick={() => {
            setTransationData(record);
            setShowModal(true);
          }}
          className="mb-0 cursor-pointer"
        >
          {text.length > 50 ? text.slice(0, 50) + "..." : text}
        </p>
      ),
    },
    {
      title: "",
      key: "action",
      width: "40px",
      render: (_text: any, record: DataType) => {
        const editable = isEditing(record);
        const transactionId = record.id.toString();
        return (
          <Dropdown
            open={editable ? showOptions : false}
            className="me-2"
            onOpenChange={(open) => setShowOptions(open)}
            dropdownRender={() => (
              <TranslatioOptions
                setShowTranslationOptions={setShowOptions}
                translationData={record}
                page={searchParams.get('page')}
                screen={searchParams.get('page')}
                portal={searchParams.get('portal')!}
              />
            )}
            trigger={["click"]}
          >
            <div
              className="mb-4 me-5"
              onClick={() => {
                setEditingKey(transactionId);
              }}
            >
              <img
                src={view_more}
                className="position-absolute cursor-pointer mb-4 me-5 plus-icon"
                alt="more_icon"
              />
            </div>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      <CustomModal
        isModalVisible={showModal}
        setIsModalVisible={setShowModal}
        showCloseBtn={false}
        width="950px"
      >
        <TransactionDetails {...transationData} />
      </CustomModal>
      <div className="d-md-none">
        <MobileTable />
      </div>
      <div className="d-none d-md-block">
        <TransactionListStyle>
          <div className="d-flex justify-content-between">
            <TertiaryHeading>Screens Translations</TertiaryHeading>
            <img
              src={plus_icon}
              onClick={() => navigate("/create-translation")}
              className="cursor-pointer"
              alt="plus-icon"
            />
          </div>

          <div className="mt-2 screen-names">
            {
              loading ? <Loader /> : data && Object.keys(data).length > 0 ? (
                <div className="d-flex gap-2 flex-wrap">
                  {Array.from(new Set(Object.entries(data as [])
                    .flatMap(([_subject, translations]) => translations)
                    .map(
                      ({
                        screenName,
                      }: DataType) => screenName
                    ))).map((screenName, index) => (
                      <ScreenItemStyle key={index} selected={(selectedScreen || searchParams.get("screenName")!) === screenName ? true : false} className="mt-2 mb-0 pb-1 text-center cursor-pointer" onClick={() => setSelectedScreen(screenName)}>{screenName}</ScreenItemStyle>
                    )
                    )
                  }
                </div>
              ) : <h6 className="text-center mb-0 font-bold">No Data Available</h6>
            }
          </div>
          <Table
            columns={columns}
            pagination={{
              current: page,
              pageSize: 20, onChange: (page) => {
                setPage(page);
                searchParams.set("page", page.toString());
                setSearchParams(searchParams)
              },
            }}

            loading={loading}
            scroll={{
              x: "auto",
              y: "75vh",
            }}
            className="mt-20"
            dataSource={data && Object.keys(data).length > 0 ? translationsData.map(
              ({
                id,
                labelKey,
                screenName,
                en,
                pt,
                es,
                ar,
                ur,
                bn,
                ru,
                fr,
                de,
                ja,
                pa,
                sw,
                te,
                mr,
                hi,
                ind,
                zh
              }: TransactionDetailTypes) => ({
                id,
                screenName,
                labelKey,
                English: en,
                Spanish: es,
                Arabic: ar,
                Portuguese: pt,
                Urdu: ur,
                Chinese: zh,
                Hindi: hi,
                Bengali: bn,
                Russian: ru,
                French: fr,
                German: de,
                Japanese: ja,
                Punjabi: pa,
                Swahili: sw,
                Telugu: te,
                Marathi: mr,
                Indonesian: ind
              })
            ) : []}
          />
        </TransactionListStyle>
      </div>
    </>
  );
};

export default TransactionList;
