import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import { Outlet } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

const Home = () => {
  return (
    <Wrapper>
      <Container fluid>
        <Row>
          <Col md={2}>
            <Sidebar />
          </Col>
          <Col md={10}>
            <Navbar />
            <div className="righ-side">
              <Outlet />
            </div>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default Home;

const Wrapper = styled.div`
  margin: 30px 0px 20px 0px;
`;
