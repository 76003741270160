import styled from "styled-components";
import {
  fontFamilyMedium,
  fontFamilyRegular,
  lightBlue3,
  lightBorder,
  lightGrey12,
  lightGrey6,
  lightGrey7,
  secondaryDark3,
  tertiaryBlue2,
  whiteColor,
} from "../../components/GlobalStyle";

type screenItemStyle={
selected:boolean
}
export const ScreenItemStyle = styled.p<screenItemStyle>`

color:${props=>props.selected?"rgb(0, 97, 151)":"black"};
border-bottom:${props=>props.selected?"2px solid rgb(0, 97, 151)":"none"};
font-family:${props=>props.selected?fontFamilyMedium:fontFamilyRegular};
font-size:${props=>props.selected?"16px":"14px"};
`;
const TransactionListStyle = styled.div`
  margin-top: 20px;
  border-radius: 20px;
  border: 1px solid ${lightBorder};
  padding: 20px;
  background-color: ${whiteColor};

  .screen-names{
    background-color: ${lightBlue3};
    padding:10px;
    border-radius:10px;
  }


  .title {
    font-size: 22px;
    color: ${tertiaryBlue2};
    font-family: ${fontFamilyRegular};
  }

  .table-link {
    color: ${secondaryDark3} !important;
    font-family: ${fontFamilyMedium};
    font-size: 16px !important;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .ant-table-body::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .ant-table-body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .btn {
    display: flex;
    justify-content: flex-end;
    margin-top: -25px;
  }

  .ant-table-thead {
    background-color: ${lightGrey6};
    border-radius: 8px;
    .ant-table-cell {
      background: ${lightGrey12};
      color: ${tertiaryBlue2};
      font-family: ${fontFamilyMedium};
      padding: 8px 16px;
      font-size: 16px;
    }
  }
  .ant-table-tbody {
    .ant-table-cell {
      padding: 3px 16px;
      &::before {
        height: 0 !important;
      }
      a {
        text-decoration: none;
        color: ${lightGrey7};
        font-size: 14px;
        &:first-child {
        }
      }
    }
  }
  .ant-table-tbody > tr > td {
    border-bottom: none !important;
  }
  .action_icons {
    width: 23px;
    height: 22px;
    padding: 3px;
    border-radius: 2px;
    cursor: pointer;
  }
  .deleteicon {
    background: #d63229 !important;
    cursor: pointer;
  }
  .editicon {
    background: #007aff !important;
    cursor: pointer;
  }
`;
export default TransactionListStyle;

export const TransactionListCardStyle = styled.div`
  .plus-icon {
    right: 5%;
    bottom: 5%;
    position: fixed;
    z-index: 30;
  }
  display: flex;
  justify-content: space-between;
  p {
    margin: 0;
    font-size: 16px;
  }

  .inner-section {
    padding-bottom: 10px;
    .card:first-child {
      margin-top: 12px;
    }
    .card:not(:first-child) {
      margin-top: 20px;
    }
    .card {
      padding: 12px;

      .name-section {
        .name {
          font-weight: 600;
        }
        .id {
          color: #156985;
        }
      }

      .details {
        margin-top: 10px;
        font-size: 16px;
      }
      .details-1 {
        margin-top: 10px;
        font-size: 13px;
      }

      .price-section {
        margin-top: 14px;
      }

      .actions-section {
        margin-top: 16px;
        img {
          background-color: #e1e2e2;
          padding: 10px;
          height: 35px;
        }
        img:not(:first-child) {
          margin-left: 4px;
        }

        .warn-actions {
          img:first-child {
            background-color: #c7112b;
          }
          img:last-child {
            background-color: #007aff;
          }
        }
      }
    }
  }
`;

export const CreateTransactionStyle = styled.div`
  margin-top: 20px;
  border-radius: 20px;
  border: 1px solid ${lightBorder};
  padding: 20px;
  background-color: ${whiteColor};
  #mt-20 {
    margin-top: 20px;
  }
  .is-invalid {
    border: 1px solid red;
    background: white;
    border-radius: 8px;
    padding: 10px;
    width: 100%;
    width: -moz-available;
    outline: none;
    margin-bottom: 8px;
  }
`;

export const UpdateTransactionStyle = styled.div`
  border-radius: 20px;
  border: 1px solid ${lightBorder};
  padding: 20px;
  margin-top: 20px;
  background-color: ${whiteColor};
  #mt-20 {
    margin-top: 20px;
  }
  .is-invalid {
    border: 1px solid red;
    background: white;
    border-radius: 8px;
    padding: 10px;
    width: 100%;
    width: -moz-available;
    outline: none;
    margin-bottom: 8px;
  }
`;

export const TransactionDetailsStyle = styled.div`
  border-radius: 14px;
  background-color: ${whiteColor};
  padding: 20px;
  #mt-20 {
    margin-top: 20px;
  }
  p,
  h3 {
    margin-bottom: 0px;
  }
  .language {
    font-size: 16px;
    font-family: ${fontFamilyMedium};
  }
  .title {
    font-size: 18px;
    font-family: ${fontFamilyMedium};
    margin-bottom: 0px;
  }
  .sub-title {
    font-size: 18px;
    font-family: ${fontFamilyRegular};
  }
`;
