import { useRef } from "react";
import NavbarStyle from "./style";
import searchIcon from "../../assets/icons/ic_search(1).svg";
import notificationIcon from "../../assets/icons/ic_notification.svg";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import {
  fontFamilyMedium,
} from "../GlobalStyle";
import { useGlobalContext } from "../../context/context";
import { Form, Input, InputRef } from "antd";
import { RootState } from "../../redux/store";
import avatarPlaceholder from "../../assets/icons/ic_use_placeholder.svg";
import ic_cloud from "../../assets/icons/ic_cloud.svg";
import FormControl from "../FormControl";
import { Formik } from "formik";
import * as Yup from "yup";

function Navbar() {
  const {
    searchText,
    setSearchText,
  } = useGlobalContext();
  const loginData = useAppSelector((state: RootState) => state.loginData);
  const navigate = useNavigate();
  const searchRef = useRef<InputRef>(null);

  const initialValues: any = {};
  const validationSchema = Yup.object({});

  // navigate to details
  const navigateToDetails = () => {
    navigate(`user-profile/${loginData.data?.userDetails.id}`);
  };

  return (
    <NavbarStyle>
      <Container>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={() => { }}
        >
          {(formik) => {
            return (
              <Form onFinish={formik.handleSubmit}>
                <div>
                  <Row>
                    <Col md={6} className="filter-area">
                      {/* <div className="filter-area d-flex"> */}
                      <Row>
                        <Col md={6}>
                          <div className="filter-area-category">
                            <FormControl
                              control="select"
                              name="status"
                              padding="2px"
                              border="none"
                              options={[]}
                              defaultValue={formik.values.status}
                              placeholder="Select Categery "
                              labelFamily={fontFamilyMedium}
                              className={
                                formik.errors.status && formik.touched.status
                                  ? "is-invalid"
                                  : "customInput"
                              }
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <Input
                            ref={searchRef}
                            value={searchText}
                            className="border-0 p-2 search-input"
                            placeholder="Search ..."
                            onChange={(e) => setSearchText(e.target.value)}
                            suffix={<img src={searchIcon} alt="search-icon" />}
                          />
                        </Col>
                      </Row>
                      {/* </div> */}
                    </Col>
                    <Col md={3}>
                      <div className="date-area d-flex">
                        <img
                          src={ic_cloud}
                          alt="weather"
                          className="me-2 flag"
                          width="26px"
                          height="26px"
                        />
                        <p
                          className="name ms-2 cursor-pointer"
                          onClick={navigateToDetails}
                        >
                          23/08/23 - 7:22 PM
                        </p>
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="d-flex gap-3">
                        <img
                          className="notifications"
                          src={notificationIcon}
                          alt="notifications"
                        />
                        <img
                          className="profile-img"
                          src={avatarPlaceholder}
                          alt="profile"
                          onClick={navigateToDetails}
                        />
                        <img
                          src={ic_cloud}
                          alt="weather"
                          className="me-2 flag"
                          width="26px"
                          height="26px"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </NavbarStyle>
  );
}

export default Navbar;
