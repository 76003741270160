import { Formik } from "formik";
import * as Yup from "yup";
import { Row, Col } from "react-bootstrap";
import {
  TertiaryHeading,
  fontFamilyMedium,
  lightBlue4,
  lightDark3,
  title,
} from "../../components/GlobalStyle";
import FormControl from "../../components/FormControl";
import { UpdateTransactionStyle } from "./style";
import CustomButton from "../../components/CustomButton/CustomButton";
import { Form } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import usePostEvent from "../../hooks/usePostEvent";
import { useState } from "react";
import CustomModal from "../../components/Modal/CustomModal";
import MartailModal from "../../components/CustomModals/MartailModal";
import { update_translation_url } from "../../utils/api_urls";
import { PortalType, TransactionDataTypes } from "./type";
import { useGlobalContext } from "../../context/context";

// initial values types
export type transactionValuesType = {
  roleType: "USER" | "Student" | "SCHOOL" | "ADMIN";
  screenName: string;
  labelKey: string;
  en: string;
  es: string;
  pt: string;
  ar: string;
  ur: string;
  bn: string;
  ru: string;
  hi: string;
  zh: string;
  fr: string;
  de: string;
  ja: string;
  pa: string;
  sw: string;
  te: string;
  mr: string;
  ind: string;

};

interface TranslationTypes extends TransactionDataTypes {
  page: string | null;
  screen: string | null;
  portal: string | null
}

type stateTypes = {
  state: TranslationTypes;
};


const CreateTransaction = () => {
  const navigate = useNavigate();
  const { loading, apiDataPromise } = usePostEvent();
  const [showModal, setShowModal] = useState(false);
  const { state }: stateTypes = useLocation();
  const { portalType } = useGlobalContext();

  if (!state) {
    alert("in not found state")
    navigate("/portal=USER&page=1");
    return null;
  }

  const { id, screenName, labelKey, English: en, Spanish: es, Portuguese: pt, Arabic: ar, Urdu: ur,

    Bengali: bn,
    Russian: ru,
    French: fr,
    German: de,
    Japanese: ja,
    Punjabi: pa,
    Swahili: sw,
    Telugu: te,
    Marathi: mr,
    Chinese: zh,
    Hindi: hi,
    Indonesian: ind,

    page, portal, } =
    state;

  // initial values
  const initialValues: transactionValuesType = {
    roleType: portalType as PortalType,
    screenName,
    labelKey,
    en,
    es,
    pt,
    ar,
    ur,
    zh,
    hi,
    bn,
    ru,
    fr,
    de,
    ja,
    pa,
    sw,
    te,
    mr,
    ind
  };

  // validation schema
  const validationSchema = Yup.object({
    roleType: Yup.string().required("Field is required!"),
    screenName: Yup.string().required("Field is required!"),
    labelKey: Yup.string().required("Field is required!"),
    en: Yup.string().required("Field is required!"),
    es: Yup.string().required("Field is required!"),
    pt: Yup.string().required("Field is required!"),
    ar: Yup.string().required("Field is required!"),
    ur: Yup.string().required("Field is required!"),
    zh: Yup.string().required("Field is required!"),
    hi: Yup.string().required("Field is required!"),
    bn: Yup.string().required("Field is required!"),
    ru: Yup.string().required("Field is required!"),
    fr: Yup.string().required("Field is required!"),
    de: Yup.string().required("Field is required!"),
    ja: Yup.string().required("Field is required!"),
    pa: Yup.string().required("Field is required!"),
    sw: Yup.string().required("Field is required!"),
    te: Yup.string().required("Field is required!"),
    mr: Yup.string().required("Field is required!"),
    ind: Yup.string().required("Field is required!")
  });

  // handle submit
  const handleSubmit = async (values: transactionValuesType) => {
    await apiDataPromise(update_translation_url, { id, ...values });
    setShowModal(true);
    setTimeout(() => {
      setShowModal(false);
      navigate(`/translation-list?page=${page}`, {
        state: {
          page,
          screenName,
          portal
        },
        replace: true
      });
    }, 2000);
  };

  return (
    <UpdateTransactionStyle>
      <CustomModal
        isModalVisible={showModal}
        setIsModalVisible={setShowModal}
        showCloseBtn={false}
      >
        <MartailModal
          doTask={() => {
            navigate("/portal=USER&page=1");
            setShowModal(false);
          }}
          title="Update Created"
          description="Transalation updated successfully!"
        />
      </CustomModal>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => {
          return (
            <Form onFinish={formik.handleSubmit} className="pb-2">
              <Row>
                <TertiaryHeading>Update Translations</TertiaryHeading>
                <Row>
                  <Col>
                    <div id="mt-20">
                      <FormControl
                        control="input"
                        type="text"
                        name="screenName"
                        color={lightDark3}
                        padding="8px"
                        label="Screen Name"
                        placeholder="Enter Screen Name"
                        className={
                          formik.errors.screenName && formik.touched.screenName
                            ? "is-invalid"
                            : "customInput"
                        }
                      />
                    </div>
                  </Col>
                  <Col>
                    <div id="mt-20">
                      <FormControl
                        control="input"
                        type="text"
                        name="labelKey"
                        color={lightDark3}
                        padding="8px"
                        label="Label Key Name"
                        placeholder="Enter Label Key Name"
                        className={
                          formik.errors.labelKey && formik.touched.labelKey
                            ? "is-invalid"
                            : "customInput"
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <Col sm={12} id="mt-20">
                  <FormControl
                    control="input"
                    type="text"
                    name="en"
                    color={lightDark3}
                    padding="8px"
                    label="English"
                    placeholder="Enter Content"
                    className={
                      formik.errors.en && formik.touched.en
                        ? "is-invalid"
                        : "customInput"
                    }
                  />
                </Col>
                <Col sm={12} id="mt-20">
                  <FormControl
                    control="input"
                    type="text"
                    name="pt"
                    color={lightDark3}
                    padding="8px"
                    label="Português"
                    placeholder="Insira o conteúdo"
                    className={
                      formik.errors.pt && formik.touched.pt
                        ? "is-invalid"
                        : "customInput"
                    }
                  />
                </Col>
                <Col sm={12} id="mt-20">
                  <FormControl
                    control="input"
                    type="text"
                    name="es"
                    color={lightDark3}
                    padding="8px"
                    label="española"
                    placeholder="Insira o conteúdo"
                    className={
                      formik.errors.es && formik.touched.es
                        ? "is-invalid"
                        : "customInput"
                    }
                  />
                </Col>

                <Col sm={12} id="mt-20">
                  <FormControl
                    control="input"
                    type="text"
                    name="bn"
                    color={lightDark3}
                    fontFamily={fontFamilyMedium}
                    padding="8px"
                    label="Bengala"
                    placeholder="অনুগ্রহ করে পাঠ্য লিখুন"
                    className={
                      formik.errors.bn && formik.touched.bn
                        ? "is-invalid"
                        : "customInput"
                    }
                  />
                </Col>

                <Col sm={12} id="mt-20">
                  <FormControl
                    control="input"
                    type="text"
                    name="ru"
                    color={lightDark3}
                    fontFamily={fontFamilyMedium}
                    padding="8px"
                    label="Russian"
                    placeholder="Пожалуйста, введите текст"
                    className={
                      formik.errors.ru && formik.touched.ru
                        ? "is-invalid"
                        : "customInput"
                    }
                  />
                </Col>

                <Col sm={12} id="mt-20">
                  <FormControl
                    control="input"
                    type="text"
                    name="fr"
                    color={lightDark3}
                    fontFamily={fontFamilyMedium}
                    padding="8px"
                    label="French"
                    placeholder="S’il vous plaît entrer du texte"
                    className={
                      formik.errors.fr && formik.touched.fr
                        ? "is-invalid"
                        : "customInput"
                    }
                  />
                </Col>
                <Col sm={12} id="mt-20">
                  <FormControl
                    control="input"
                    type="text"
                    name="de"
                    color={lightDark3}
                    fontFamily={fontFamilyMedium}
                    padding="8px"
                    label="German"
                    placeholder="Bitte geben Sie den Text ein"
                    className={
                      formik.errors.de && formik.touched.de
                        ? "is-invalid"
                        : "customInput"
                    }
                  />
                </Col>
                <Col sm={12} id="mt-20">
                  <FormControl
                    control="input"
                    type="text"
                    name="ja"
                    color={lightDark3}
                    fontFamily={fontFamilyMedium}
                    padding="8px"
                    label="Japanese"
                    placeholder="テキストを入力してください"
                    className={
                      formik.errors.ja && formik.touched.ja
                        ? "is-invalid"
                        : "customInput"
                    }
                  />
                </Col>
                <Col sm={12} id="mt-20">
                  <FormControl
                    control="input"
                    type="text"
                    name="pa"
                    color={lightDark3}
                    fontFamily={fontFamilyMedium}
                    padding="8px"
                    label="Punjabi"
                    placeholder="ਕਿਰਪਾ ਕਰਕੇ ਟੈਕਸਟ ਦਾਖਲ ਕਰੋ"
                    className={
                      formik.errors.pa && formik.touched.pa
                        ? "is-invalid"
                        : "customInput"
                    }

                  />
                </Col>
                <Col sm={12} id="mt-20">
                  <FormControl
                    control="input"
                    type="text"
                    name="sw"
                    color={lightDark3}
                    fontFamily={fontFamilyMedium}
                    padding="8px"
                    label="Swahili"
                    placeholder="Tafadhali ingiza maandishi"
                    className={
                      formik.errors.sw && formik.touched.sw
                        ? "is-invalid"
                        : "customInput"
                    }
                  />
                </Col>
                <Col sm={12} id="mt-20">
                  <FormControl
                    control="input"
                    type="text"
                    name="te"
                    color={lightDark3}
                    fontFamily={fontFamilyMedium}
                    padding="8px"
                    label="Telugu"
                    placeholder="مواد درج کریں۔"
                    className={
                      formik.errors.te && formik.touched.te
                        ? "is-invalid"
                        : "customInput"
                    }
                  />
                </Col>
                <Col sm={12} id="mt-20">
                  <FormControl
                    control="input"
                    type="text"
                    name="mr"
                    color={lightDark3}
                    fontFamily={fontFamilyMedium}
                    padding="8px"
                    label="Marathi"
                    placeholder="कृपया मजकूर प्रविष्ट करा"
                    className={
                      formik.errors.mr && formik.touched.mr
                        ? "is-invalid"
                        : "customInput"
                    }
                  />
                </Col>
                <Col sm={12} id="mt-20">
                  <FormControl
                    control="input"
                    type="text"
                    name="zh"
                    color={lightDark3}
                    fontFamily={fontFamilyMedium}
                    padding="8px"
                    label="Chinese"
                    placeholder="请输文字"
                    className={
                      formik.errors.zh && formik.touched.zh
                        ? "is-invalid"
                        : "customInput"
                    }
                  />
                </Col>
                <Col sm={12} id="mt-20">
                  <FormControl
                    control="input"
                    type="text"
                    name="hi"
                    color={lightDark3}
                    fontFamily={fontFamilyMedium}
                    padding="8px"
                    label="Hindi"
                    placeholder="कृपया पाठ दर्ज करें"
                    className={
                      formik.errors.hi && formik.touched.hi
                        ? "is-invalid"
                        : "customInput"
                    }
                  />
                </Col>
                <Col sm={12} id="mt-20">
                  <FormControl
                    control="input"
                    type="text"
                    name="ind"
                    color={lightDark3}
                    fontFamily={fontFamilyMedium}
                    padding="8px"
                    label="Indonesian"
                    placeholder="Silakan masukkan teks"
                    className={
                      formik.errors.ind && formik.touched.ind
                        ? "is-invalid"
                        : "customInput"
                    }
                  />
                </Col>
                <Col sm={12} id="mt-20">
                  <FormControl
                    control="input"
                    type="text"
                    name="ar"
                    color={lightDark3}
                    padding="8px"
                    label="عربي"
                    textAlign="end"
                    labelAlign="end"
                    fontFamily={fontFamilyMedium}
                    placeholder="أدخل المحتوى"
                    className={
                      formik.errors.ar && formik.touched.ar
                        ? "is-invalid"
                        : "customInput"
                    }
                  />
                </Col>
                <Col sm={12} id="mt-20">
                  <FormControl
                    control="input"
                    type="text"
                    name="ur"
                    color={lightDark3}
                    fontFamily={fontFamilyMedium}
                    padding="8px"
                    label="اردو"
                    placeholder="مواد درج کریں۔"
                    className={
                      formik.errors.ur && formik.touched.ur
                        ? "is-invalid"
                        : "customInput"
                    }
                    textAlign="end"
                    labelAlign="end"
                  />
                </Col>

              </Row>
              <div className="d-flex justify-content-end mt-20">
                <CustomButton
                  bgcolor={lightBlue4}
                  color={title}
                  padding="8px"
                  width="452px"
                  type="submit"
                  title="Update"
                  margin="auto"
                  loading={loading}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </UpdateTransactionStyle>
  );
};

export default CreateTransaction;
