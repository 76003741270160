import { Suspense, useEffect } from "react";
import "./App.css";
import { ToastContainer } from "react-toastify";
import AppRoutes from "./routes/AppRoutes";
import Head from "./components/Head/Head";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorPage } from "./screens/pages";
import { SkeletonTheme } from "react-loading-skeleton";
import { lightGrey13 } from "./components/GlobalStyle";
import { local_storage_admin_key } from "./utils/axios.utils";
// api method for refresh token
import { useLocation } from "react-router-dom";

export const MAP_API = "AIzaSyC6PLT5-mrVFJcFqFixXZTW4d7Fj1EZg3Q";

// getting local storage auth data
export const loginData = localStorage.getItem(local_storage_admin_key) ?? null;

function App() {
  const { pathname } = useLocation();

  // scroll to top when we navigate to new route
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [pathname]);

  return (
    <>
      <Head title="Xp Delivery" />
      <SkeletonTheme
        baseColor={lightGrey13}
        highlightColor={lightGrey13}
      // borderRadius="0.5rem"
      >
        <ToastContainer />
        <ErrorBoundary
          FallbackComponent={ErrorPage}
          onError={() => console.log("Error happened")}
        >
          <Suspense fallback={<h6>Loading...</h6>}>
            <AppRoutes />
          </Suspense>
        </ErrorBoundary>
      </SkeletonTheme>
    </>
  );
}

export default App;
