import { Routes, Route } from "react-router-dom";
import {
  Login,
  CreateUser,
  Dashboard,
  Home,
  ErrorPage404,
} from "../screens/pages";
import ForgetPassword from "../screens/ForgetPassword/ForgetPasword";
import CreateTransaction from "../screens/Translation/CreateTranslation";
import UpdateTransaction from "../screens/Translation/UpdateTransaction";
import TranslationList from "../screens/Translation/TranslationList";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />}>
        <Route index element={<Dashboard />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="create-translation" element={<CreateTransaction />} />
        <Route path="update-translation" element={<UpdateTransaction />} />
        <Route path="translation-list" element={<TranslationList />} />
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="register" element={<CreateUser />} />
      <Route path="forget-password" element={<ForgetPassword />} />
      {/* error page */}
      <Route path="*" element={<ErrorPage404 />} />
    </Routes>
  );
}

export default AppRoutes;
