import styled from "styled-components";
import {
  lightBlue,
  lightBlue4,
  lightBorder,
  title,
  whiteColor,
} from "../GlobalStyle";

const SidebarStyle = styled.div`
  width: 200px;
  height: 100vh;
  position: fixed;
  padding: 20px;
  overflow-y: auto;
  z-index: 20;
  border-radius: 20px;
  background-color: ${whiteColor};
  border: 1px solid ${lightBorder};

  .logo {
    height: 86px;
  }
  .login-btn {
    position: absolute;
    width: 100%;
    bottom: 6%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .selected {
    border-radius: 10px;
    background-color: ${lightBlue};
    color: ${title};
    padding: 8px;
    width: 100%;
  }
  .menu-items {
    margin-top: 32px;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .expand-menu {
    transition: all 1s ease-out;
    transform-origin: top;
  }
  p {
    color: ${title};
    margin: 0;
    cursor: pointer;
  }
  .inner-container {
    margin-top: 28px;
    .selected {
      border-radius: 10px;
      background: ${lightBlue4};
    }
    img {
      cursor: pointer;
    }
  }
`;

export default SidebarStyle;
