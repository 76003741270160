import { Row, Col, Container } from "react-bootstrap";
import { TertiarySubtitle } from "../../components/GlobalStyle";
import { TransactionDetailsStyle } from "./style";
import { DataType } from "./TranslationList";

const TransactionDetails: React.FC<DataType> = ({
  screenName,
  labelKey,
  English,
  Spanish,
  Chinese,
  Hindi,
  Arabic,
  Portuguese,
  Urdu,
  Bengali,
  Russian,
  French,
  German,
  Japanese,
  Punjabi,
  Swahili,
  Telugu,
  Marathi,
  Indonesian

}) => {

  const translationsData = new Map<string, string>([
    ["English", English],
    ["Spanish", Spanish],
    ["Portuguese", Portuguese],
    ["Arabic", Arabic],
    ["Urdu", Urdu],
    ["Mandarin Chinese", Chinese],
    ["Hindi", Hindi],
    ["Bengali", Bengali],
    ["Russian", Russian],
    ["French", French],
    ["German", German],
    ["Japanese", Japanese],
    ["Punjabi", Punjabi],
    ["Swahili", Swahili],
    ["Telugu", Telugu],
    ["Marathi", Marathi],
    ["Indonesian", Indonesian]
  ]);
  return (
    <Container>
      <TransactionDetailsStyle>
        <Row>
          <Col>
            <h6 className="title">
              Screen Name: <span className="sub-title">{screenName}</span>
            </h6>
          </Col>
          <Col>
            <h6 className="title">
              Label name: <span className="sub-title">{labelKey}</span>
            </h6>
          </Col>
        </Row>
        {Array.from(translationsData).map(([key, translation], index) => (
          <Row id="mt-20" key={index}>
            <Col md={4}>
              <TertiarySubtitle className="language">
                {key}
              </TertiarySubtitle>
            </Col>
            <Col md={8}>
              <p>{translation}</p>
            </Col>
          </Row>
        ))}
      </TransactionDetailsStyle>
    </Container>
  );
};

export default TransactionDetails;
